<template>
  <v-dialog
    v-model="dialog"
    persistent
    width="800"
    @click:outside="clickOutside"
  >
    <template v-slot:activator="{ on, attrs }">
      <p id="goodsReceiptForm" v-bind="attrs" v-on="on" @click="openForm"></p>
    </template>
    <v-card>
      <v-card-title>
        <span class="text-h5" style="margin: auto">FORMULIR PENERIMAAN</span>
      </v-card-title>
      <v-form>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Dokumen
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  PIC
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Perusahaan
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. FPB
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. PO
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Surat Jalan Vendor
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Form
                </p>

                <v-text-field
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  No. Terima
                </p>

                <v-text-field
                  disabled
                  dense
                  outlined
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Tgl. Terima
                </p>

                <v-text-field
                  dense
                  type="date"
                  name="startdate"
                  step="1"
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>

              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diterima Dari
                </p>

                <v-autocomplete
                  item-text="name"
                  item-value="id"
                  style="font-size: 12px; height: 45px"
                  return-object
                  dense
                  outlined
                  clearable
                  disabled
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diserahkan Oleh
                </p>

                <v-text-field
                  dense
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
              <v-col cols="12" md="4" class="d-flex flex-column">
                <p
                  class="text-left"
                  style="
                    margin: 0;
                    font-size: 12px;
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.5);
                  "
                >
                  Diterima Oleh
                </p>

                <v-text-field
                  dense
                  outlined
                  clearable
                  style="font-size: 12px; height: 45px"
                />
              </v-col>
            </v-row>

            <div style="margin-top: 30px">
              <v-data-table
                max-height="50vh"
                mobile-breakpoint="0"
                style="cursor: pointer; border: 1px solid rgba(0, 0, 0, 0.2)"
                :headers="grHeaders"
                :items="detailItems"
                :items-per-page="10"
                item-key="id"
              >
                <template v-slot:[`item.no`]="{ index }">
                  <div style="width: 35px">
                    {{ index + 1 }}
                  </div>
                </template>
                <template v-slot:[`item.product_code`]="{ item }">
                  <div style="width: 95px; font-size: 12px">
                    {{ item.product_code }}
                  </div>
                </template>
                <template v-slot:[`item.product_name`]="{ item }">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <div
                        v-bind="attrs"
                        v-on="on"
                        style="
                          text-align: left;
                          width: 250px;
                          font-size: 12px;
                          padding: 0;
                        "
                      >
                        {{
                          `${
                            item.product_code !== null
                              ? '(' + item.product_code + ')'
                              : ''
                          } ${item.product_name}`
                        }}
                      </div>
                    </template>
                    <span>
                      {{
                        `${
                          item.product_code !== null
                            ? '(' + item.product_code + ')'
                            : ''
                        } ${item.product_name}`
                      }}</span
                    >
                  </v-tooltip>
                </template>
                <template v-slot:[`item.request_qty`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.request_qty }}
                  </div>
                </template>
                <template v-slot:[`item.return_qty`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.return_qty }}
                  </div>
                </template>
                <template v-slot:[`item.done_qty`]="{ item }">
                  <div style="width: 45px; font-size: 12px">
                    {{ item.done_qty }}
                  </div>
                </template>
                <template v-slot:[`item.desc`]="{ item }">
                  <div style="width: 215px; font-size: 12px">
                    {{ item.desc }}
                  </div>
                </template>
              </v-data-table>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <div style="padding: 0 25px 20px; width: 100%">
            <v-btn
              style="width: 100%"
              color="blue darken-1"
              class="white--text"
              @click="saveForm"
            >
              SIMPAN
            </v-btn>
            <v-btn
              style="width: 100%; margin-top: 15px"
              color="pink"
              class="white--text"
              @click="closeDialog"
            >
              BATAL
            </v-btn>
          </div>
          <!-- <v-btn color="blue darken-1" text type="submit"> Save </v-btn> -->
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'FormGr',
  props: [],
  data() {
    return {
      dialog: false,
      form: null,
      detailItems: []
    }
  },
  computed: {
    grHeaders() {
      const arrHeaders = [
        {
          text: 'No',
          value: 'no',
          align: 'left'
          // sortable: true,
        },
        {
          text: 'Kode Barang',
          value: 'product_code',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Nama Barang',
          value: 'product_name',
          align: 'left'
          // sortable: false,
        },
        {
          text: 'Qty Request',
          value: 'request_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Return',
          value: 'return_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Qty Selesai',
          value: 'done_qty',
          align: 'left',
          // sortable: true,
          sortable: false
        },
        {
          text: 'Keterangan',
          value: 'desc',
          align: 'left',
          // sortable: true,
          sortable: false
        }
      ]
      // if (this.detail.status.id === 0) {
      return arrHeaders
      // }
    }
  },
  mounted() {},
  methods: {
    onResize() {
      this.wWidth = window.innerWidth
    },
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    openForm() {
      console.log('hallo world')
      this.detailItems = [
        {
          no: 1,
          product_name: 'LAPTOP DELL RYZEN 5',
          product_code: 'AST-0016',
          vendor: 'TOKOPEDIA',
          request_qty: 3,
          return_qty: 0,
          done_qty: 3,
          desc: 'Kondisi barang baik'
        }
      ]
    },
    // clear() {
    //   this.$emit('loadPage')
    // },
    clickOutside() {
      //   this.closeDialog()
    },
    closeDialog() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    },
    saveForm() {
      setTimeout(() => {
        this.dialog = false
      }, 200)
    }
  }
}
</script>
